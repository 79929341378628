<template>
  <div>
    <ModuleLocked v-if="!getActivePlan.status" />
    <section v-else class="vid-title-sec">
      <v-tour
        :options="{
          labels: {
            buttonSkip: 'Atla',
            buttonPrevious: 'Geri',
            buttonNext: 'İleri',
            buttonStop: 'Bitir',
          },
        }"
        name="newCourse"
        :steps="
          steps.map((step) => ({
            target: step.target,
            header: {
              title: $t(step.header.title),
            },
            content: $t(step.content),
          }))
        "
      ></v-tour>
      <!-- is active geldiğinde içeri gönder -->
      <delete-product
        :product="form"
        v-if="form.buyerCount === 0"
      ></delete-product>
      <div v-if="!edit">
        <h4 class="text-primary">
          <strong>{{ $t("createVideo") }}</strong>
        </h4>
        <p class="mt-1 mb-4">
          {{ $t("createVideoHint") }}
        </p>
      </div>
      <div class="container">
        <form @submit.prevent="SET_MIDDLEWARE">
          <div class="row align-items-center">
            <div class="col-12 col-lg-7">
              <div class="vid-title" id="v-step-nc-0">
                <h2 class="title-hd">
                  <i class="icon-pinned"></i> {{ $t("enterCourseTitle") }} *
                </h2>
                <div class="form_field">
                  <input
                    type="text"
                    required
                    v-model="form.title"
                    maxlength="100"
                    :placeholder="$t('addHereMax100Char')"
                  />
                </div>
              </div>
              <!--vid-title-->
              <div class="abt-vidz-pr" id="v-step-nc-1">
                <h2 class="title-hd">
                  <i class="icon-pinned"></i> {{ $t("shortDesc") }} *
                </h2>
                <div class="form_field">
                  <textarea
                    :placeholder="$t('shortDescMax250')"
                    required
                    v-model="form.description"
                    maxlength="250"
                  ></textarea>
                </div>
              </div>
              <!--abt-vidz-->
            </div>
            <div class="text-center col-12 col-lg-5">
              <iframe
                v-if="$i18n.locale === 'tr'"
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/cZGyLIgWvMQ?si=xgPw0bO1ilQzFlJw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
              <iframe
                v-else
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/eRpW6hy25ys?si=tu0_FMm9_HIUNVNn"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="abt-vidz-pr" id="v-step-nc-2">
            <h2 class="title-hd">
              <i class="icon-pinned"></i> {{ $t("courseOverview") }} *
            </h2>
            <div class="form_field">
              <vue-editor
                id="editor"
                :placeholder="$t('explainCourseDetail')"
                :editorToolbar="[
                  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                  [{ size: ['small', false, 'large', 'huge'] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [
                    { align: '' },
                    { align: 'center' },
                    { align: 'right' },
                    { align: 'justify' },
                  ],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                  [{ color: [] }, { background: [] }],
                  ['link'],
                  ['clean'],
                ]"
                v-model="form.overview"
              >
              </vue-editor>
            </div>
          </div>
          <!--abt-vidz-->
          <div class="cls-vidz">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-6 col-12" id="v-step-nc-3">
                <div class="option">
                  <h2 class="title-hd">
                    <i class="icon-language"></i> {{ $t("selectCourseLang") }} *
                  </h2>
                  <div class="form_field">
                    <select required v-model="form.langCode">
                      <option value="tr">{{ $t("tr") }}</option>
                      <option value="en">{{ $t("en") }}</option>
                      <option value="de">{{ $t("de") }}</option>
                    </select>
                  </div>
                </div>
                <!--option end-->
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 col-12" id="v-step-nc-4">
                <div class="option">
                  <h2 class="title-hd">
                    <i class="icon-flag"></i> {{ $t("courseLevel") }} *
                  </h2>
                  <div class="form_field">
                    <select required v-model="form.level">
                      <option :value="1">{{ $custom$t("beginning") }}</option>
                      <option :value="2">
                        {{ $custom$t("intermediate") }}
                      </option>
                      <option :value="3">{{ $custom$t("advanced") }}</option>
                      <option :value="4">{{ $t("all") }}</option>
                    </select>
                  </div>
                </div>
                <!--option end-->
              </div>
              <div class="col-sm-6 col-12" id="v-step-nc-5">
                <div class="option">
                  <h2 class="title-hd">
                    <i class="icon-grid"></i> {{ $t("category") }} *
                  </h2>
                  <div class="form_field">
                    <select
                      :disabled="getCategoriesAreLoading"
                      required
                      v-model="form.categoryId"
                    >
                      <option
                        :value="item.id"
                        v-for="(item, i) in getCategories"
                        :key="i"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--option end-->
              </div>
            </div>
            <h4
              class="my-4"
              id="v-step-nc-6"
              v-if="getSettings.departmentId !== 2"
            >
              <i class="icon-paid_sub"></i> {{ $t("productPrice") }}
            </h4>
            <ProductIsOffer
              v-if="getSettings.departmentId !== 2"
              v-model="form.isOffer"
            ></ProductIsOffer>
            <ProductPrice
              v-if="getSettings.departmentId !== 2"
              v-model="form"
            ></ProductPrice>
          </div>
          <div class="my-4">
            <Cropper
              id="v-step-nc-7"
              :image="form.imagePath"
              @change:image="SET_FILE"
            ></Cropper>
            <Video
              v-if="getSettings.departmentId !== 2"
              @change:link="SET_LINK"
              id="v-step-nc-8"
              :video="form.videoPath"
              @change:video="SET_VIDEO"
            ></Video>
            <hr />
            <h4 id="v-step-nc-9" v-if="getSettings.departmentId !== 2">
              <i class="icon-settings"></i> {{ $t("productSettings") }}
            </h4>
            <p class="mt-1 mb-4">
              {{ $t("productSettingsDescription") }}
            </p>
            <ProductIsBuyableAndExpiredAt
              v-if="getSettings.departmentId !== 2"
              :accessDuration="
                form.accessDuration === null ? 0 : form.accessDuration
              "
              :isBuyable="form.isBuyable === 1"
              @change:isBuyable="(e) => (form.isBuyable = e ? 1 : 0)"
              @change:accessDuration="(e) => (form.accessDuration = e)"
            />
          </div>
          <div>
            <publish-on-home-page
              v-if="getSettings.departmentId !== 2"
              v-model="form.isShowcase"
            ></publish-on-home-page>
          </div>
          <div>
            <SharableProduct
              v-if="getSettings.departmentId !== 2"
              v-model="form.isSharable"
            ></SharableProduct>
          </div>
          <div>
            <ProductIsLocked v-model="form.isLocked"></ProductIsLocked>
          </div>
          <div>
            <SelectProductOwner
              v-model="form.userInfo"
              :edit="edit"
              ref="userList"
            ></SelectProductOwner>
          </div>
          <!--cls-vidz end-->
          <div class="clearfix"></div>
          <div class="btn-sbmit bg-white p-2 shadow">
            <button type="submit" :disabled="getNewCourseIsLoading">
              <loading v-if="getNewCourseIsLoading"></loading>
              <span v-else
                >{{ $t("submitCourse") }} <i class="icon-tick"></i>
              </span>
            </button>
          </div>
        </form>
      </div>
      <hr />
      <IsActiveProduct
        :isActive="1"
        :form="product"
        v-if="edit"
      ></IsActiveProduct>
    </section>
  </div>
  <!--vid-title-sec-->
</template>

<script>
import Cropper from "./ImageCropper.vue";
import Video from "./Video.vue";
import { VueEditor } from "vue2-editor";
import IsActiveProduct from "./IsActiveProduct.vue";
import ProductIsBuyableAndExpiredAt from "./ProductIsBuyableAndExpiredAt.vue";
import ProductIsOffer from "./ProductIsOffer.vue";
import ProductIsLocked from "./ProductIsLocked.vue";
import SelectProductOwner from "./SelectProductOwner.vue";
import ProductPrice from "./ProductPrice.vue";
import PublishOnHomePage from "./PublishOnHomePage.vue";
import SharableProduct from "./SharableProduct.vue";
import DeleteProduct from "./DeleteProduct.vue";
import AlertBox from "../../mixins/AlertBox";
import Modules from "../../mixins/Modules";
import ModuleLocked from "../Packages/ModuleLocked.vue";
export default {
  mixins: [AlertBox, Modules],
  components: {
    Cropper,
    Video,
    VueEditor,
    IsActiveProduct,
    SelectProductOwner,
    ProductIsBuyableAndExpiredAt,
    PublishOnHomePage,
    SharableProduct,
    DeleteProduct,
    ProductIsLocked,
    ProductIsOffer,
    ProductPrice,
    ModuleLocked,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      steps: [
        {
          target: "#v-step-nc-0", // We're using document.querySelector() under the hood
          header: {
            title: "step1.title",
          },
          content: "step1.description",
          offset: 0,
        },
        {
          target: "#v-step-nc-1", // We're using document.querySelector() under the hood
          header: {
            title: "step2.title",
          },
          content: "step2.description",
          offset: 0,
        },
        {
          target: "#v-step-nc-2", // We're using document.querySelector() under the hood
          header: {
            title: "step3.title",
          },
          content: "step3.description",
          offset: 0,
        },
        {
          target: "#v-step-nc-3", // We're using document.querySelector() under the hood
          header: {
            title: "step4.title",
          },
          content: "step4.description",
          offset: 0,
        },
        {
          target: "#v-step-nc-4", // We're using document.querySelector() under the hood
          header: {
            title: "step5.title",
          },
          content: "step5.description",
          offset: 0,
        },
        {
          target: "#v-step-nc-5", // We're using document.querySelector() under the hood
          header: {
            title: "step6.title",
          },
          content: "step6.description",
          offset: 0,
        },
        {
          target: "#v-step-nc-6", // We're using document.querySelector() under the hood
          header: {
            title: "step7.title",
          },
          content: "step7.description",
          offset: 0,
        },
        {
          target: "#v-step-nc-7", // We're using document.querySelector() under the hood
          header: {
            title: "step8.title",
          },
          content: "step8.description",
          offset: 0,
        },
        {
          target: "#v-step-nc-8", // We're using document.querySelector() under the hood
          header: {
            title: "step9.title",
          },
          content: "step9.description",
          offset: 0,
        },
        {
          target: "#v-step-nc-9", // We're using document.querySelector() under the hood
          header: {
            title: "step10.title",
          },
          content: "step10.description",
          offset: 0,
        },
      ],
      form: {
        user: null,
        title: "",
        description: "",
        overview: "",
        imagePath: "",
        videoPath: "",
        image: null,
        video: null,
        priceTRY: 0,
        priceUSD: 0,
        priceEUR: 0,
        discountTRY: 0,
        discountUSD: 0,
        discountEUR: 0,
        langCode: "tr",
        level: 1,
        hiddenInfo: "",
        categoryId: null,
        isBuyable: 1,
        accessDuration: 0,
        isShowcase: 0,
        isSharable: 0,
        isLocked: 0,
        isOffer: 0,
      },
      files: {
        image: "",
        video: "",
      },
    };
  },
  computed: {
    // kategorilerin listesi alınır.
    getCategories() {
      return this.$store.state.Category.categories;
    },
    // kateori listesi loading
    getCategoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
    // ürün ekleme loading
    getNewCourseIsLoading() {
      return this.$store.state.Courses.newCourseIsLoading;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
  },
  methods: {
    // eğer yeni kayıt query'de category var ise.
    SET_CATEGORY() {
      const categoryId = Number(this.$route.query.category);
      if (categoryId) this.form.categoryId = categoryId;
    },
    SET_IF_EDIT() {
      this.form = {
        ...this.product,
        categoryId: this.product.category.id,
        imagePath: this.$api.image(this.product.imagePath),
        videoPath: this.$api.image(this.product.videoPath),
        video: null,
        image: null,
      };
    },

    SET_FILE(e) {
      this.form.image = e.file; // image file'ı
      this.files.image = e.uri; // image linki
    },
    SET_VIDEO(e) {
      this.form.video = e.file; // video file'ı
      this.files.video = e.uri; /// video linki
    },
    SET_LINK(link) {
      this.form.video = null;
      this.files.video = link;
    },
    // middleware
    SET_MIDDLEWARE(e) {
      e.preventDefault();
      if (this.edit) this.SEND_UPDATE_COURSE();
      else this.SEND_COURSE();
    },
    // ürünü güncelle
    async SEND_UPDATE_COURSE() {
      const response = await this.$store.dispatch("SEND_UPDATE_COURSE", {
        fd: this.SET_COURSE_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath:
            this.files.image === null
              ? this.product.imagePath
              : this.files.image,
          videoPath:
            this.files.video === null
              ? this.product.videoPath
              : this.files.video,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.go(0);
        }, 1000);
    },
    // ürünü gönder
    async SEND_COURSE() {
      if (this.form.image === null)
        return this.SET_ALERT_BOX("danger", this.$t("imageIsRequired"));
      // if (this.form.video === null) return;
      const response = await this.$store.dispatch("SEND_NEW_COURSE", {
        fd: this.SET_COURSE_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath: this.files.image,
          videoPath: this.files.video,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.push(`/product/courses-${response}?path=videos`);
        }, 2500);
    },
    // ürünü formdataya taşı.
    SET_COURSE_BY_FORMDATA(form) {
      const fd = new FormData();
      fd.append("UserId", form.userInfo.id);
      fd.append("Title", form.title);
      fd.append("CategoryId", form.categoryId);
      fd.append("Description", form.description);
      fd.append("DiscountEUR", form.discountEUR);
      fd.append("DiscountTRY", form.discountTRY);
      fd.append("DiscountUSD", form.discountUSD);
      fd.append("HiddenInfo", form.hiddenInfo);
      fd.append("LangCode", form.langCode);
      fd.append("Level", form.level);
      fd.append("Overview", form.overview);
      fd.append("PriceEUR", form.priceEUR);
      fd.append("PriceTRY", form.priceTRY);
      fd.append("PriceUSD", form.priceUSD);
      fd.append(
        "AccessDuration",
        form.accessDuration === 0 ? null : form.accessDuration
      );
      fd.append("IsBuyable", form.isBuyable);
      fd.append("IsShowcase", form.isShowcase || 0);
      fd.append("IsSharable", form.isSharable);
      fd.append("IsLocked", form.isLocked || 0);
      fd.append("IsOffer", form.isOffer || 0);
      fd.append("ImagePath", form.imagePath);
      if (this.form.video !== null) {
        fd.append("Video", form.video);
        fd.append("VideoPath", "");
      } else if (this.form.video === null && this.files.video !== "")
        fd.append(
          "VideoPath",
          "https://www.youtube.com/watch?v=" + this.files.video
        );
      else fd.append("VideoPath", form.videoPath);
      if (this.form.image !== null) fd.append("Image", form.image);
      return fd;
    },
    START_GUIDE_TOUR() {
      const q = this.$route.query;
      if (q.tour) {
        this.$tours["newCourse"].start();
      }
    },
  },
  created() {
    if (this.edit) this.SET_IF_EDIT();
    this.SET_CATEGORY();
  },
  mounted() {
    this.START_GUIDE_TOUR();
  },
};
</script>
